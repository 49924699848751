import { simplifiedNotes, simplify } from "../domain/music-theory";
export function createPiano() {
    var pianoDiv = document.getElementById("piano");
    if (!pianoDiv || pianoDiv.hasChildNodes()) {
        return false;
    }
    var _loop_1 = function (i) {
        var note = simplifiedNotes[i % 12];
        var key = note.replace("♯", "sharp");
        var octave = Math.floor(i / 12);
        var keyDiv = document.createElement("div");
        keyDiv.classList.add('key', "key-".concat(key));
        keyDiv.classList.add(key.length === 1 ? "white-key" : "black-key");
        keyDiv.id = "key-".concat(key).concat(octave);
        keyDiv.onmousedown = function () { return setNotePressed(note, octave, true); };
        keyDiv.onmouseup = function () { return setNotePressed(note, octave, false); };
        keyDiv.onmouseleave = function () { return setNotePressed(note, octave, false); };
        pianoDiv.appendChild(keyDiv);
    };
    for (var i = 3; i <= 26; i++) {
        _loop_1(i);
    }
    return true;
}
function getKeyElements(note, octave) {
    var noteName = simplify(note).replace("♯", "sharp");
    if (octave === "all") {
        return document.getElementsByClassName("key-".concat(noteName));
    }
    else {
        return [document.getElementById("key-".concat(noteName).concat(octave))];
    }
}
function highlightKey(note, octave, className, on) {
    var keyElements = getKeyElements(note, octave);
    for (var i = 0; i < keyElements.length; i++) {
        var keyElement = keyElements[i];
        if (keyElement) {
            if (on) {
                keyElement.classList.add(className);
            }
            else {
                keyElement.classList.remove(className);
            }
        }
    }
}
var pressedNotes = new Set();
var onNotePressed = [];
var onPressedNotesChanged = [];
export var onNoteHit = function (callback) {
    onNotePressed.push(callback);
};
export var onNotesHit = function (notes, callback, once) {
    var notesSet = new Set(notes.map(function (note) { return simplify(note); }));
    var thisOnPressedNotesChanged = function (pressedNotes) {
        if (pressedNotes.size === notesSet.size
            && Array.from(pressedNotes).every(function (note) { return notesSet.has(note); })) {
            if (once) {
                onPressedNotesChanged = onPressedNotesChanged.filter(function (callback) { return callback !== thisOnPressedNotesChanged; });
            }
            callback();
        }
    };
    onPressedNotesChanged.push(thisOnPressedNotesChanged);
};
export var clearOnNotesHit = function () {
    onNotePressed = [];
    onPressedNotesChanged = [];
};
export var setHighlightNoteColors = function (className, notes) {
    var simplified = notes.map(function (note) { return simplify(note); });
    for (var i = 0; i < simplifiedNotes.length; i++) {
        var note = simplifiedNotes[i];
        highlightKey(note, "all", className, simplified.includes(note));
    }
};
export var setNotePressed = function (note, octave, pressed) {
    note = simplify(note);
    highlightKey(note, octave, "active", pressed);
    if (pressed) {
        pressedNotes.add(note);
        onNotePressed.forEach(function (callback) { return callback(note); });
    }
    else {
        pressedNotes.delete(note);
    }
    onPressedNotesChanged.forEach(function (callback) { return callback(pressedNotes); });
};
export var setNoteDots = function (notes) {
    var dotsDiv = document.getElementById("dots");
    if (!dotsDiv) {
        var pianoDiv = document.getElementById("piano");
        dotsDiv = document.createElement("div");
        dotsDiv.id = "dots";
        pianoDiv.appendChild(dotsDiv);
    }
    var unusedDotDivs = getUnusedDots(dotsDiv, notes);
    var newDotDivs = createNewDots(dotsDiv, notes);
    replaceUnusedWithNewDivs(dotsDiv, unusedDotDivs, newDotDivs);
};
function getUnusedDots(dotsDiv, notes) {
    var unusedDotDivs = [];
    var dotDivs = Array.from(dotsDiv.children);
    for (var i = 0; i < dotDivs.length; i++) {
        var dotDiv = dotDivs[i];
        var note = dotDiv.className.split(' ')[2] + (dotDiv.className.includes('sharp') ? '♯' : '');
        if (!notes.map(simplify).includes(note)) {
            unusedDotDivs.push(dotDiv);
        }
    }
    return unusedDotDivs;
}
function createNewDots(dotsDiv, notes) {
    var newDotDivs = [];
    for (var o = 1; o <= 2; o++) {
        for (var i = 0; i < notes.length; i++) {
            var note = simplify(notes[i]);
            var classes = [note[0], note.endsWith('♯') ? 'sharp' : 'natural'];
            var dotElement = dotsDiv.querySelector('.dot-' + o + '.' + classes.join('.'));
            if (!dotElement) {
                dotElement = document.createElement('div');
                dotsDiv.appendChild(dotElement);
                dotElement.className = 'dot dot-' + o + ' ' + classes.join(' ');
                newDotDivs.push(dotElement);
            }
            if (i == 0) {
                dotElement.classList.add('root');
            }
            else {
                dotElement.classList.remove('root');
            }
        }
    }
    return newDotDivs;
}
function replaceUnusedWithNewDivs(dotsDiv, unusedDotDivs, newDotDivs) {
    // this is just DOM replacement to enable CSS transitions
    // find the closest pair of unused and new divs
    var distances = unusedDotDivs.map(function (unusedDotDiv) { return newDotDivs.map(function (newDotDiv) {
        var unusedDotDivRect = unusedDotDiv.getBoundingClientRect();
        var newDotDivRect = newDotDiv.getBoundingClientRect();
        return {
            unusedDotDiv: unusedDotDiv,
            newDotDiv: newDotDiv,
            distance: Math.abs(unusedDotDivRect.left - newDotDivRect.left)
        };
    }); }).flat();
    distances.sort(function (a, b) { return a.distance - b.distance; });
    var _loop_2 = function () {
        var closest = distances.shift();
        if (closest.distance > dotsDiv.clientWidth * 0.9) {
            // too far apart. Will be removed later
        }
        else {
            // copy the css, which causes a transition
            closest.unusedDotDiv.className = closest.newDotDiv.className;
            dotsDiv.removeChild(closest.newDotDiv);
            distances = distances.filter(function (pair) { return pair.unusedDotDiv !== closest.unusedDotDiv && pair.newDotDiv !== closest.newDotDiv; });
            unusedDotDivs = unusedDotDivs.filter(function (div) { return div !== closest.unusedDotDiv; });
        }
    };
    while (distances.length > 0) {
        _loop_2();
    }
    while (unusedDotDivs.length > 0) {
        dotsDiv.removeChild(unusedDotDivs.shift());
    }
}
