var _a, _b, _c, _d, _e, _f, _g;
var fifthsNoteElements = document.querySelectorAll("#circle-of-fifths .note");
var scaleElement = document.getElementById("scale-name");
export var setScaleName = function (scaleName) {
    scaleElement.innerText = scaleName;
};
var modeArrowElement = document.getElementById("mode-arrow");
export var setArrowPosition = function (mode) {
    modeArrowElement.className = 'arrow up ' + (mode === null || mode === void 0 ? void 0 : mode.toLowerCase());
};
export var setCircleNotes = function (scaleNotes) {
    for (var i_1 = 0; i_1 < fifthsNoteElements.length; i_1++) {
        var classes = fifthsNoteElements[i_1].classList;
        classes.remove('in-scale', 'note-off-start', 'note-off-end', 'note-1', 'note-2', 'note-3', 'note-4', 'note-5', 'note-6', 'note-7');
    }
    var noteClassNames = scaleNotes.map(function (n) { return n.replace('♯', '-sharp').replace('♭', '-flat'); });
    var firstElemIndex = Array.from(fifthsNoteElements).findIndex(function (e) { return e.classList.contains(noteClassNames[0]); }) - 1;
    var noteIndexes = Array.from(Array(9).keys()).map(function (i) { return (firstElemIndex + i + 12) % 12; });
    fifthsNoteElements[noteIndexes[0]].classList.add('note-off-start');
    for (var i = 1; i < noteIndexes.length - 1; i++) {
        if (scaleNotes.length < i) {
            break;
        }
        var elem = fifthsNoteElements[noteIndexes[i]];
        elem.innerHTML = scaleNotes[i - 1];
        elem.classList.add('note-' + i.toString(), 'in-scale');
        elem.setAttribute('data-set-brightness', (7 - i).toString());
    }
    fifthsNoteElements[noteIndexes[noteIndexes.length - 1]].classList.add('note-off-end');
};
var shiftCallback = function () { };
export var onShift = function (callback) {
    shiftCallback = callback;
};
var noteClickCallback = function () { };
export var onNoteClick = function (callback) {
    noteClickCallback = callback;
};
(_a = document.getElementById("circle-of-fifths")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function (e) {
    var clickedElem = e.target;
    var clickedAttr = clickedElem === null || clickedElem === void 0 ? void 0 : clickedElem.getAttribute('data-set-brightness');
    if (clickedAttr) {
        var clickedBrightness = parseInt(clickedAttr);
        noteClickCallback(clickedElem.innerHTML, clickedBrightness);
    }
});
(_b = document.getElementById("fifth-up")) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function () {
    shiftCallback(1, 0);
});
(_c = document.getElementById("fifth-down")) === null || _c === void 0 ? void 0 : _c.addEventListener("click", function () {
    shiftCallback(-1, 0);
});
(_d = document.getElementById("mode-brighten-parallel")) === null || _d === void 0 ? void 0 : _d.addEventListener("click", function () {
    shiftCallback(0, 1);
});
(_e = document.getElementById("mode-brighten-relative")) === null || _e === void 0 ? void 0 : _e.addEventListener("click", function () {
    shiftCallback(-1, 1);
});
(_f = document.getElementById("mode-darken-parallel")) === null || _f === void 0 ? void 0 : _f.addEventListener("click", function () {
    shiftCallback(0, -1);
});
(_g = document.getElementById("mode-darken-relative")) === null || _g === void 0 ? void 0 : _g.addEventListener("click", function () {
    shiftCallback(1, -1);
});
var keyFunctions = {
    "ArrowUp": function () { return shiftCallback(1, 0); },
    "ArrowDown": function () { return shiftCallback(-1, 0); },
    "ArrowLeft": function () { return shiftCallback(-1, 1); },
    "ArrowRight": function () { return shiftCallback(1, -1); },
};
addEventListener("keydown", function (e) {
    if (e.key in keyFunctions) {
        keyFunctions[e.key]();
    }
});
