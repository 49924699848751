var _a, _b;
import * as settings from "../domain/settings";
function showMenu() {
    var _a, _b;
    (_a = document.getElementById("settings-menu")) === null || _a === void 0 ? void 0 : _a.classList.add("visible");
    (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.removeAllRanges();
}
function hideMenu() {
    var _a;
    (_a = document.getElementById("settings-menu")) === null || _a === void 0 ? void 0 : _a.classList.remove("visible");
}
(_a = document.getElementById("settings-cog")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () { return showMenu(); });
(_b = document.getElementById("settings-close")) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function () { return hideMenu(); });
document.addEventListener("click", function (e) {
    var targetElem = e.target;
    if (!targetElem.closest("#settings-menu") && !targetElem.closest("#settings-cog")) {
        hideMenu();
    }
});
function createSetting(setting) {
    var elem = document.createElement("div");
    elem.classList.add("setting");
    var label = document.createElement("label");
    label.innerHTML = setting.heading;
    elem.appendChild(label);
    var p = document.createElement("p");
    p.innerHTML = setting.description;
    elem.appendChild(p);
    var button = document.createElement("button");
    elem.appendChild(button);
    var settingsElem = document.getElementById("settings");
    settingsElem === null || settingsElem === void 0 ? void 0 : settingsElem.appendChild(elem);
    function updateButton() {
        var controls = setting.createControls();
        button.innerHTML = controls.buttonText;
        button.onclick = function () {
            settings.update(controls.changeSetting);
            hideMenu();
        };
    }
    settings.onChange(function () { return updateButton(); });
    updateButton();
}
export var init = function () {
    createSetting({
        heading: "Detect Scale",
        description: "Automatically detects the scale from the notes you play.",
        createControls: function () {
            if (settings.get().scale instanceof Array) {
                return {
                    buttonText: "Stop",
                    changeSetting: function (s) {
                        s.scale = settings.randomScale();
                    }
                };
            }
            else {
                return {
                    buttonText: "Detect",
                    changeSetting: function (s) {
                        s.scale = [];
                    }
                };
            }
        }
    });
    createSetting({
        heading: "Start Jam",
        description: "Generates popular and random 4-chord progressions for you to play along to.",
        createControls: function () {
            if (settings.get().chords === "jam") {
                return {
                    buttonText: "Stop Jam",
                    changeSetting: function (s) {
                        s.chords = "all";
                    }
                };
            }
            else {
                return {
                    buttonText: "Start Jam",
                    changeSetting: function (s) {
                        s.chords = "jam";
                    }
                };
            }
        }
    });
};
