var notes = {
    "A": 0,
    "A♯": 1,
    "B♭": 1,
    "B": 2,
    "B♯": 3,
    "C♭": 2,
    "C": 3,
    "C♯": 4,
    "D♭": 4,
    "D": 5,
    "D♯": 6,
    "E♭": 6,
    "E": 7,
    "E♯": 8,
    "F♭": 7,
    "F": 8,
    "F♯": 9,
    "G♭": 9,
    "G": 10,
    "G♯": 11,
    "A♭": 11,
};
var simplifiedNotes = ["A", "A♯", "B", "C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯"];
var simplify = function (note) { return simplifiedNotes[notes[note]]; };
var majorScaleIntervals = [0, 2, 4, 5, 7, 9, 11];
var shift = function (note, degree) { return simplifiedNotes[(12 + notes[note] + degree) % 12]; };
var createScale = function (root, intervals) { return intervals.map(function (interval) { return shift(root, interval); }); };
var scales = simplifiedNotes.map(function (note) { return createScale(note, majorScaleIntervals); });
var rotate = function (intervals, degree) { return intervals.map(function (interval) { return (12 + interval - intervals[degree]) % 12; }).sort(function (a, b) { return a - b; }); };
var modes = ["ionian", "dorian", "phrygian", "lydian", "mixolydian", "aeolian", "locrian"];
var modeIntervals = modes.map(function (_, index) { return rotate(majorScaleIntervals, index); });
var getInterval = function (root, note) { return (12 + notes[note] - notes[root]) % 12; };
var triadIntervals = {
    major: [0, 4, 7],
    minor: [0, 3, 7],
    dimin: [0, 3, 6],
    //augmented: [0, 4, 8],
};
var triadNames = Object.keys(triadIntervals);
var getTriadName = function (intervals) { return triadNames.find(function (name) { return triadIntervals[name].every(function (interval, i) { return interval === intervals[i]; }); }); };
var getTriad = function (root, triad) { return triadIntervals[triad].map(function (interval) { return shift(root, interval); }); };
var getTriadsInKey = function (key) { return [0, 1, 2, 3, 4, 5, 6].map(function (i) { return [key[i], key[(i + 2) % 7], key[(i + 4) % 7]]; }); };
var romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII"];
var triadRomanNumerals = {
    "major": function (i) { return romanNumerals[i].toUpperCase(); },
    "minor": function (i) { return romanNumerals[i].toLowerCase(); },
    "dimin": function (i) { return romanNumerals[i].toLowerCase() + "°"; },
    "": function (_) { return "unknown"; },
};
var modesByBrightness = ["locrian", "phrygian", "aeolian", "dorian", "mixolydian", "ionian", "lydian"];
var shiftBrightness = function (mode, steps) { return modesByBrightness[(7 + modesByBrightness.indexOf(mode) + steps) % 7]; };
//const modesByFifths = [ "lydian", "phrygian", "dorian", "ionian", "locrian", "aeolian", "mixolydian" ];
var leftNoteFromRoot = {
    "lydian": 0,
    "ionian": 5,
    "mixolydian": 10,
    "dorian": 3,
    "aeolian": 8,
    "phrygian": 1,
    "locrian": 6,
};
var getLeftNote = function (root, mode) { return shift(root, leftNoteFromRoot[mode]); };
var getCircleOfFifthsFromLeftNote = function (leftNote) { return createScale(leftNote, [0, 7, 2, 9, 4, 11, 6]); };
var getCircleOfFifths = function (root, mode) { return getCircleOfFifthsFromLeftNote(getLeftNote(root, mode)); };
export { notes, simplifiedNotes, simplify, shift, scales, modes, modesByBrightness, shiftBrightness, modeIntervals, getInterval, createScale, triadIntervals, triadNames, getTriad, getTriadName, getTriadsInKey, triadRomanNumerals, getCircleOfFifths };
