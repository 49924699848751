import { generateChordProgression } from "./chord-progressions";
import { getOptimalRepresentation } from "./optimal-representation";
var notes = {
    "A": 0,
    "A♯": 1,
    "B♭": 1,
    "B": 2,
    "B♯": 3,
    "C♭": 2,
    "C": 3,
    "C♯": 4,
    "D♭": 4,
    "D": 5,
    "D♯": 6,
    "E♭": 6,
    "E": 7,
    "E♯": 8,
    "F♭": 7,
    "F": 8,
    "F♯": 9,
    "G♭": 9,
    "G": 10,
    "G♯": 11,
    "A♭": 11,
};
export var simplifiedNotes = ["A", "A♯", "B", "C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯"];
export var simplify = function (note) { return simplifiedNotes[notes[note]]; };
var majorScaleIntervals = [0, 2, 4, 5, 7, 9, 11];
export var shift = function (note, degree) { return simplifiedNotes[(12 + notes[note] + degree) % 12]; };
var createScaleFromIntervals = function (root, intervals) { return intervals.map(function (interval) { return shift(root, interval); }); };
var createScaleFromMode = function (root, mode) { return createScaleFromIntervals(root, modeIntervals[modes.indexOf(mode)]); };
var scales = simplifiedNotes.map(function (note) { return createScaleFromIntervals(note, majorScaleIntervals); });
var rotate = function (intervals, degree) { return intervals.map(function (interval) { return (12 + interval - intervals[degree]) % 12; }).sort(function (a, b) { return a - b; }); };
export var modes = ["ionian", "dorian", "phrygian", "lydian", "mixolydian", "aeolian", "locrian"];
var modeIntervals = modes.map(function (_, index) { return rotate(majorScaleIntervals, index); });
var getInterval = function (root, note) { return (12 + notes[note] - notes[root]) % 12; };
var triadIntervals = {
    maj: [0, 4, 7],
    min: [0, 3, 7],
    dim: [0, 3, 6],
    //aug: [0, 4, 8],
};
var triadNames = Object.keys(triadIntervals);
var getTriadName = function (intervals) { return triadNames.find(function (name) { return triadIntervals[name].every(function (interval, i) { return interval === intervals[i]; }); }); };
var getTriad = function (root, triad) { return triadIntervals[triad].map(function (interval) { return shift(root, interval); }); };
var getTriadsInKey = function (key) { return [0, 1, 2, 3, 4, 5, 6].map(function (i) { return [key[i], key[(i + 2) % 7], key[(i + 4) % 7]]; }); };
var romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII"];
var triadRomanNumerals = {
    "maj": function (i) { return romanNumerals[i].toUpperCase(); },
    "min": function (i) { return romanNumerals[i].toLowerCase(); },
    "dim": function (i) { return romanNumerals[i].toLowerCase() + "°"; },
    "": function (_) { return "unknown"; },
};
var modesByBrightness = ["locrian", "phrygian", "aeolian", "dorian", "mixolydian", "ionian", "lydian"];
export var getModeByBrightness = function (brightness) { return modesByBrightness[(7 + brightness) % 7]; };
export var getModeByNotes = function (root, notes) {
    if (notes.length !== 7) {
        return;
    }
    var _loop_1 = function () {
        var modeNotes = createScaleFromIntervals(root, modeIntervals[i]);
        if (notes.every(function (note) { return modeNotes.indexOf(note) >= 0; })) {
            return { value: modes[i] };
        }
    };
    for (var i = 0; i < modes.length; i++) {
        var state_1 = _loop_1();
        if (typeof state_1 === "object")
            return state_1.value;
    }
};
export var shiftBrightness = function (mode, steps) {
    var currentBrightness = modesByBrightness.indexOf(mode);
    var newBrightness = currentBrightness + steps;
    var newMode = getModeByBrightness(modesByBrightness.indexOf(mode) + steps);
    var noteShift = Math.floor(newBrightness / 7); // shift the root note if we jump from locrian to lydian
    return { mode: newMode, noteShift: noteShift };
};
//const modesByFifths = [ "lydian", "phrygian", "dorian", "ionian", "locrian", "aeolian", "mixolydian" ];
var leftNoteFromRoot = {
    "lydian": 0,
    "ionian": 5,
    "mixolydian": 10,
    "dorian": 3,
    "aeolian": 8,
    "phrygian": 1,
    "locrian": 6,
};
var getLeftNote = function (root, mode) { return shift(root, leftNoteFromRoot[mode]); };
var getCircleOfFifthsFromLeftNote = function (leftNote) { return createScaleFromIntervals(leftNote, [0, 7, 2, 9, 4, 11, 6]); };
var getCircleOfFifths = function (root, mode) { return getCircleOfFifthsFromLeftNote(getLeftNote(root, mode)); };
var toTitleCase = function (str) { return str[0].toUpperCase() + str.slice(1); };
export var createScaleData = function (scale) {
    var notes = getOptimalRepresentation(scale instanceof Array ? scale : createScaleFromMode(scale.root, scale.mode));
    var mode = scale instanceof Array ? undefined : scale.mode;
    var triadsInSelectedKey = getTriadsInKey(notes);
    var triads = triadsInSelectedKey.map(function (triad) {
        var triadIntervals = triad.map(function (note) { return getInterval(triad[0], note); });
        var triadName = getTriadName(triadIntervals);
        var triadRomanNumeral = triadRomanNumerals[triadName || ""](triadsInSelectedKey.indexOf(triad));
        return {
            root: triad[0],
            quality: triadName,
            numeral: triadRomanNumeral,
            notes: triad,
            type: triadName || triadIntervals
        };
    });
    return {
        name: mode ? notes[0] + " " + toTitleCase(mode) : "Detecting...",
        mode: mode,
        notes: notes,
        triads: triads,
        circleOfFifths: mode ? getOptimalRepresentation(getCircleOfFifths(notes[0], mode)) : notes,
        generateChordProgression: function () { return generateChordProgression(mode); },
    };
};
