import { WebMidi } from "webmidi";
export var setupMidiListener = function (onNotePressed) {
    WebMidi.enable()
        .then(function () {
        var inputs = WebMidi.inputs;
        if (inputs.length === 0) {
            console.log("No MIDI input devices detected.");
        }
        else {
            inputs.forEach(function (input) {
                console.log("Listening to MIDI device: ".concat(input.name));
                var addNoteListener = function (eventName, on) {
                    input.addListener(eventName, function (event) {
                        var _a;
                        var note = (event.note.name + ((_a = event.note.accidental) !== null && _a !== void 0 ? _a : "").replace('#', '♯'));
                        onNotePressed(note, "all", on);
                    });
                };
                addNoteListener("noteon", true);
                addNoteListener("noteoff", false);
            });
        }
    })
        .catch(function (err) { return console.error("Could not enable WebMidi:", err); });
    var noteKeyMap = {
        "z": "C",
        "s": "C♯",
        "x": "D",
        "d": "D♯",
        "c": "E",
        "v": "F",
        "g": "F♯",
        "b": "G",
        "h": "G♯",
        "n": "A",
        "j": "A♯",
        "m": "B",
    };
    addEventListener("keydown", function (event) {
        var key = noteKeyMap[event.key];
        if (key === undefined)
            return;
        onNotePressed(key, "all", true);
    });
    addEventListener("keyup", function (event) {
        var key = noteKeyMap[event.key];
        if (key === undefined)
            return;
        onNotePressed(key, "all", false);
    });
};
