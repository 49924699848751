import { shift, createScaleData, getModeByBrightness, shiftBrightness, getModeByNotes } from "./music-theory";
import * as settings from "./settings";
export var createState = function () {
    settings.save(settings.get());
    return {
        getScale: function () { return createScaleData(settings.get().scale); },
        moveFifth: function (steps) {
            settings.update(function (s) {
                if (s.scale instanceof Array) {
                    // can't move fifths in a custom scale
                    return;
                }
                s.scale.root = shift(s.scale.root, steps * 7);
            });
        },
        moveBrightness: function (steps) {
            settings.update(function (s) {
                if (s.scale instanceof Array) {
                    // can't move brightness in a custom scale
                    return;
                }
                var _a = shiftBrightness(s.scale.mode, steps), mode = _a.mode, noteShift = _a.noteShift;
                s.scale.mode = mode;
                s.scale.root = shift(s.scale.root, noteShift);
            });
        },
        setScale: function (root, brightness) {
            settings.update(function (s) {
                s.scale = {
                    root: root,
                    mode: getModeByBrightness(brightness)
                };
            });
        },
        isInJamMode: function () { return settings.get().chords === "jam"; },
        isDetectingScale: function () { return settings.get().scale instanceof Array; },
        detectNote: function (note) {
            settings.update(function (s) {
                var currentScale = s.scale;
                if (!(currentScale instanceof Array)) {
                    return;
                }
                if (currentScale.indexOf(note) === -1) {
                    currentScale.push(note);
                }
                var mode = getModeByNotes(currentScale[0], currentScale);
                s.scale = mode !== undefined ? {
                    root: currentScale[0],
                    mode: mode,
                } : currentScale;
            });
        },
        onSettingsChange: settings.onChange,
    };
};
