export var setProgression = function (triads, progression) {
    var notationCrotchetElem = document.getElementById("crotchets");
    notationCrotchetElem.innerHTML = '';
    notationCrotchetElem.className = 'contains-' + progression.length;
    for (var i = 0; i < progression.length; i++) {
        var triad = triads[progression[i] - 1];
        notationCrotchetElem.innerHTML += "\n            <div class=\"chord\">\n                <div class=\"name\">".concat(triad.root, " <span class=\"spacer\"></span> ").concat(triad.quality, "</div>\n                <div class=\"numeral\">").concat(triad.numeral, "</div>\n                <div class=\"crotchet position-").concat(i + 1, " ").concat(triad.notes[0][0], "\">&#xE1D2;<br/>&#xE1D2;<br/>&#xE1D2;</div>\n            </div>\n            ");
    }
};
export var highlightPosition = function (position) {
    var chordElements = document.querySelectorAll(".chord");
    for (var i = 0; i < chordElements.length; i++) {
        if (i === position) {
            chordElements[i].classList.add("active");
            chordElements[i].classList.add("active");
        }
        else {
            chordElements[i].classList.remove("active");
            chordElements[i].classList.remove("active");
        }
    }
};
export var setKeySig = function (scaleNotes) {
    var keySigElement = document.getElementById("key-signature");
    keySigElement.innerHTML = '';
    for (var n = 0; n < scaleNotes.length; n++) {
        var note = scaleNotes[n];
        if (note.length > 1) {
            var div = document.createElement('div');
            div.classList.add('note');
            div.classList.add(note[0]);
            div.classList.add(note.endsWith('♭') ? 'flat' : 'sharp');
            keySigElement.append(div);
        }
    }
};
