import { modes, simplifiedNotes } from "./music-theory";
function getQueryString(settings) {
    var parts = [];
    if (settings.scale instanceof Array) {
        parts.push('notes=' + settings.scale.join(','));
    }
    else {
        parts.push('root=' + settings.scale.root);
        parts.push('mode=' + settings.scale.mode);
    }
    if (settings.chords !== "all") {
        parts.push("chords=".concat(settings.chords));
    }
    return parts.join("&");
}
var randomFromArray = function (array) { return array[Math.floor(Math.random() * array.length)]; };
export function randomScale() {
    return {
        root: randomFromArray(simplifiedNotes),
        mode: randomFromArray(modes),
    };
}
function getSettings(queryString) {
    var _a, _b, _c, _d;
    var params = new URLSearchParams(queryString);
    var notes = params.get("notes");
    return {
        scale: notes === "" ? [] : (_a = notes === null || notes === void 0 ? void 0 : notes.split(",")) !== null && _a !== void 0 ? _a : {
            root: (_b = params.get("root")) !== null && _b !== void 0 ? _b : randomFromArray(simplifiedNotes),
            mode: (_c = params.get("mode")) !== null && _c !== void 0 ? _c : randomFromArray(modes),
        },
        chords: (_d = params.get("chords")) !== null && _d !== void 0 ? _d : "all",
    };
}
var onSettingsChanged = [];
export function onChange(callback) {
    onSettingsChanged.push(callback);
}
export function save(settings) {
    window.history.replaceState({}, "", "?" + getQueryString(settings));
    onSettingsChanged.forEach(function (callback) { return callback(settings); });
}
export function update(updateFunction) {
    var settings = get();
    updateFunction(settings);
    save(settings);
}
export function get() {
    return getSettings(window.location.search);
}
