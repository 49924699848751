import './ui/styles.less';
import * as circleOfFifths from './ui/circle-of-fifths';
import * as piano from "./ui/virtual-piano";
import * as notation from "./ui/notation";
import * as settingsMenu from "./ui/settings-menu";
import { setupMidiListener } from "./midi";
import { createState } from "./domain/current-state";
(function () {
    if (!piano.createPiano()) {
        // I dunno why this loads twice, so just ignore the second time
        return;
    }
    var state = createState();
    var loadScale = function () {
        var scale = state.getScale();
        circleOfFifths.setScaleName(scale.name);
        circleOfFifths.setCircleNotes(scale.circleOfFifths);
        circleOfFifths.setArrowPosition(scale.mode);
        piano.setHighlightNoteColors("in-scale", scale.notes);
        piano.setNoteDots(scale.notes);
        notation.setKeySig(scale.notes);
        notation.highlightPosition(-1);
        if (state.isDetectingScale()) {
            notation.setProgression([], []);
        }
        else if (state.isInJamMode()) {
            playAlongToRandomProgressions();
        }
        else {
            showAllChords();
        }
    };
    var playAlongToRandomProgressions = function () {
        var scale = state.getScale(); // TODO pass in?
        var progression = [];
        var newProgression = function () {
            progression = scale.generateChordProgression();
            notation.setProgression(scale.triads, progression);
        };
        var askForTriad = function (position, createNewOnHit) {
            var triad = scale.triads[progression[position] - 1];
            piano.setHighlightNoteColors("in-next-chord", triad.notes);
            piano.clearOnNotesHit();
            piano.onNotesHit(triad.notes, function () {
                if (createNewOnHit) {
                    newProgression();
                }
                notation.highlightPosition(position);
                piano.setHighlightNoteColors("in-chord", triad.notes);
                if (position === progression.length - 1) {
                    askForTriad(0, true);
                }
                else {
                    askForTriad(position + 1, false);
                }
            }, true);
        };
        piano.setHighlightNoteColors("in-chord", []);
        newProgression();
        askForTriad(0, false);
    };
    var showAllChords = function () {
        var scale = state.getScale(); // TODO pass in?
        notation.setProgression(scale.triads, [1, 2, 3, 4, 5, 6, 7]);
        piano.setHighlightNoteColors("in-next-chord", scale.notes);
        piano.clearOnNotesHit();
        var _loop_1 = function () {
            var position = i;
            var triad = scale.triads[i];
            piano.onNotesHit(triad.notes, function () {
                notation.highlightPosition(position);
                piano.setHighlightNoteColors("in-chord", triad.notes);
            }, false);
        };
        for (var i = 0; i < scale.triads.length; i++) {
            _loop_1();
        }
    };
    circleOfFifths.onShift(function (fifthShift, brightnessShift) {
        state.moveFifth(fifthShift);
        state.moveBrightness(brightnessShift);
        loadScale();
    });
    circleOfFifths.onNoteClick(function (note, brightness) {
        state.setScale(note, brightness);
        loadScale();
    });
    setupMidiListener(piano.setNotePressed);
    piano.onNoteHit(function (note) {
        if (state.isDetectingScale()) {
            state.detectNote(note);
            loadScale();
        }
    });
    state.onSettingsChange(function () {
        loadScale();
    });
    settingsMenu.init();
    loadScale();
})();
